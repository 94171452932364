
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { User } from '@/models';

export default defineComponent({
  data () {
    return {
      homepageLink: ''
    };
  },
  mounted () {
    this.homepageLink = User.getLandingPage(this.user.type);
  },
  computed: {
    ...mapState({ user: 'user', loggedIn: 'loggedIn' })
  }
});
